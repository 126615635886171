@tailwind base;
@tailwind components;
@tailwind utilities;


@layer utilities {
    @variants responsive {
      /* Hide scrollbar for Chrome, Safari and Opera */
      .no-scrollbar::-webkit-scrollbar {
          display: none;
      }

      /* Hide scrollbar for IE, Edge and Firefox */
      .no-scrollbar {
          -ms-overflow-style: none;  /* IE and Edge */
          scrollbar-width: none;  /* Firefox */
      }
    }
  }

  
/* @font-face {
  font-family: 'Lato';
  src: local('Lato'), url(./fonts/Lato-Regular.otf) format('opentype');
} */

@font-face {
  font-family: 'Jenriv Titling';
  font-weight: 400;
  font-style: normal;
  text-transform: capitalize;
  src: url(./assets/fonts/Jenriv/JenrivTitling-Regular.otf) format('opentype');
}

@font-face {
  font-family: 'DIN';
  font-weight: 400;
  src: url(./assets/fonts/DIN/DIN.ttf) format('truetype');
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
      display: none;
  }
 /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
}
}


.title{
  font-family: 'Jenriv Titling';
}

.title-xl{
font-family: 'Jenriv Titling';
font-size: 48px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.24px;
text-transform: capitalize;
}
.title-large{
color: #FFF;
text-align: center;
/* Headline V1/H4 */
font-family: 'Jenriv Titling';
font-size: 36px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.18px;
text-transform: capitalize;

}

.title-medium{
  /* text-align: center; */
  /* Headline V1/H4 */
  font-family: 'Jenriv Titling';
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.12px;
  text-transform: capitalize;
  }


.text{
  font-family: 'DIN';
}
.din-text{
  font-family: 'DIN';

}


.text-small{
color: #FBFBFB;
/* Body/Body X-Large */
font-family: 'DIN';
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.1px;
}

.text-tiny{
/* Headline V2/H7 */
font-family: 'DIN';
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.08px;
}

.text-default{
/* Body/Caption */
font-family: 'DIN';
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.06px;
}

.text-small-green{
color: #495749;
/* Headline V2/H5 */
font-family: 'DIN';
font-size: 24px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.12px;
}

.text-medium-golden{
color: var(--Gold, #E5D062);
/* Headline V2/H5 */
font-family: 'DIN';
font-size: 24px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.12px;
}

.text-tiny-golden{
color: var(--Gold, #E5D062);
/* Button Large */
font-family: 'DIN';
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.08px;
}

.truncate-twoline-text {
  display: -webkit-box;
  -webkit-line-clamp: 2;  /* Limit to 2 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}



